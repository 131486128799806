import { Auth } from 'aws-amplify'
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js'
import { navigate } from 'gatsby'

export const INVALID_CODE = 'Wrong confirmation code. Try again.'
export const INVALID_EMAIL =
  'Invalid email. Please confirm you entered it correctly.'

export const setupUserSession = async () => {
  try {
    var session = await Auth.currentSession()
    // if (session != null && session.idToken) {
    // setUser(session.idToken.payload)
    // }
  } catch (e) {
    if (e !== 'No current user') {
      console.log(e)
    }
  }
}

/**
 * Save user to local storage so we can retrieve it for login with "magic link"
 * @param {} emailAddress
 * @param {*} user
 */
export async function signinUser(emailAddress) {
  const cognitoUser = await Auth.signIn(emailAddress.toLowerCase())

  localStorage.setItem(
    'cognitoUser',
    JSON.stringify({
      email: emailAddress.toLowerCase(),
      session: cognitoUser.Session,
    })
  )

  return getUser()
}

export function getUser() {
  const user = JSON.parse(localStorage.getItem('cognitoUser'))

  if (!user) {
    return null
  }

  const userPool = new CognitoUserPool({
    UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    ClientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID,
  })
  const cognitoUser = new CognitoUser({
    Username: user.email.toLowerCase(),
    Pool: userPool,
  })
  // After this set the session to the previously stored user session
  cognitoUser.Session = user.session
  // rehydrating the user and sending the auth challenge answer
  // directly will not trigger a new email
  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')

  return cognitoUser
}

export function getAuthState() {
  return typeof window !== 'undefined'
    ? localStorage.getItem('amplify-authenticator-authState')
    : 'signIn'
}

export function signoutUser() {
  Auth.signOut()
    .then(data => console.log('logged out'))
    .catch(err => console.log(err))
  localStorage.removeItem('cognitoUser')
  navigate('/login')
}

export async function signupUser(email) {
  try {
    await Auth.signUp({
      username: email.toLowerCase(),
      password: getRandomString(30),
      attributes: {
        name: getRandomString(10),
      },
    })
  } catch (e) {
    // Ignore UsernameExistsException, try and login
    if (e.code != 'UsernameExistsException') {
      console.error(e.message)
    }
  }
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function validateCode(code) {
  var re = /^\d{6}$/
  return re.test(code)
}

export function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues)
    .map(nr => nr.toString(16).padStart(2, '0'))
    .join('')
}
